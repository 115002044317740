import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

export default ({ type, name, description, tag, img, size = "small" }) => {
  // /static/minihero-2-40382e4da5c2ecf4a40eb5597d6e28ea.jpg

  const { file } = useStaticQuery(graphql`
    query MiniHeroQuery {
      file(name: { eq: "minihero-1" }) {
        publicURL
        childImageSharp {
          fluid(quality: 100, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <BackgroundImage
      className="w-full bg-cover bg-center"
      style={{
        height: size === "big" ? "30rem" : "20rem",
        // backgroundImage: `url(${imgUrl ? imgUrl : file.publicURL})`,
        backgroundPositionY: img ? "center" : "bottom"
      }}
      fluid={img ? img.childImageSharp.fluid : file.childImageSharp.fluid}
    >
      <div className="flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-50">
        <div className="text-center">
          <h1 className="text-white text-6xl font-semibold uppercase">
            {name}
          </h1>
        </div>
      </div>
    </BackgroundImage>
  );
};
