import React from "react";
import Img from "gatsby-image";
import { graphql, useStaticQuery, Link } from "gatsby";
import SEO from "../components/MetaSeo";
import MiniHero from "../components/MiniHero";
import Layout from "../components/layout";
import WhatsappButton from "../components/WhatsappButton";

const Intercambio = () => {
  const title = "Intercâmbio";
  const slug = "intercambio";

  const { visaImg, seguroImg, classImg } = useStaticQuery(graphql`
    fragment PageImage on File {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    query IntercambioQuery {
      visaImg: file(name: { eq: "intercambio-visa" }) {
        ...PageImage
      }

      seguroImg: file(name: { eq: "intercambio-seguro" }) {
        ...PageImage
      }

      classImg: file(name: { eq: "intercambio-class" }) {
        ...PageImage
      }
    }
  `);

  return (
    <Layout>
      <SEO title={title} pathname={slug} />

      <section className="text-gray-700 body-font">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h2 className="title-font sm:text-6xl text-3xl mb-4 font-medium text-gray-900 font-family-louize tracking-tighter">
              O que é <span className="text-underline">Intercâmbio?</span>
            </h2>
            <p className="mb-8 leading-relaxed ">
              Quer saber mais informações sobre intercâmbio? Então chegou no
              lugar certo e nós podemos te explicar o porquê. No dicionário,
              intercâmbio nada mais é do que reciprocidade de relações entre
              nações.
              <br />
              <br />
              Em educação, intercâmbio refere-se a estudantes que passam um
              período que varia de um mês a anos estudando em outro país. O
              intercâmbio não está atrelado somente aos estudos, mas sim à
              sonhos, desejos, vontades, caminhos, crescimento pessoal e
              profissional.
              <br />
              <br />
              Entendemos que o intercâmbio é a porta que lhe dá acesso ao seu
              desenvolvimento principalmente como pessoa (longe de casa, dos
              amigos e familiares que amamos aprendemos a viver e crescer). Essa
              experiência só é possível quando você dá abertura aos estudos em
              outro país. Pensando nisso nós preparamos um FAQ sobre intercâmbio
              para que você saiba tudo sobre esse fantástico mundo dos
              intercambistas.
              <br />
              <br />
              São perguntas que foram feitas dos clientes para a Dreams e que
              queremos distribuir com vocês um pouco do nosso conhecimento.
              Lembrando que essa lista sempre é atualizada com mais perguntas e
              respostas.
            </p>
          </div>

          <iframe
            className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/f8ywc0-GuMs"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </section>

      <section className="text-gray-700 body-font bg-blue-100">
        <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
          <div className="text-left  w-full">
            <h2 className="title-font sm:text-6xl text-3xl mb-4 font-medium text-gray-900 font-family-louize tracking-tighter">
              Porque Contratar uma{" "}
              <span className="text-underline">Agência de Intercâmbio?</span>
            </h2>
            <p className="mb-8 leading-relaxed">
              Existem vários motivos para você contratar uma agência de
              intercâmbio, por isso, resolvemos listar 4 motivos principais.
            </p>

            <div className="flex flex-wrap">
              <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200">
                <h3 className="uppercase text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                  escolha
                </h3>
                <p className="leading-relaxed text-base mb-4">
                  Para ajudarmos na escolha do programa ideal para você.
                </p>
              </div>
              <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200">
                <h3 className=" uppercase text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                  informado
                </h3>
                <p className="leading-relaxed text-base mb-4">
                  Para você ser informado de tudo que você vai encontrar lá.
                  Afinal, agências são especialistas nisso.
                </p>
              </div>
              <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200">
                <h3 className="uppercase text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                  pagamento{" "}
                </h3>
                <p className="leading-relaxed text-base mb-4">
                  Para você ter variadas formas de pagamento e poder fazer em
                  moeda local (R$).
                </p>
              </div>
              <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200">
                <h2 className="uppercase text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                  equipe
                </h2>
                <p className="leading-relaxed text-base mb-4">
                  Ter uma equipe que fale seu idioma e que possa te ajudar
                  durante toda a sua viagem.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Como fazer intercâmbio? */}
      <section className="text-gray-700 body-font bg-red-100">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h2 className="title-font sm:text-6xl text-3xl mb-4 font-medium text-gray-900 font-family-louize tracking-tighter">
              Como fazer <span className="text-underline">Intercâmbio?</span>
            </h2>
          </div>
          <div className="flex flex-wrap -m-4">
            <div className="p-4">
              <div className="flex rounded-lg h-full bg-red-200 p-8 flex-col">
                <div className="flex-grow">
                  <p className="leading-relaxed text-base">
                    Para fazer intercâmbio basta apenas você ter em mente o
                    destino de interesse e procurar uma agência especializada no
                    assunto.A agência lhe apresentará um leque de opções de
                    escolas, oferecerá também alguns tipos de acomodação e lhe
                    fornecerá o máximo de informações necessárias para a sua
                    viagem. A Dreams dispõe de toda estrutura para lhe ajudar a
                    realizar esse sonho.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="text-gray-700 body-font">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h2 className="title-font sm:text-6xl text-3xl mb-4 font-medium text-gray-900 font-family-louize tracking-tighter">
              Eu preciso saber inglês para fazer{" "}
              <span className="text-underline">Intercâmbio?</span>
            </h2>
            <p className="mb-8 leading-relaxed ">
              As escolas sabem que a grande maioria dos alunos que vão para
              estudar no exterior tem apenas o conhecimento básico do idioma.
              Neste caso, não existe um nível mínimo exigido. Porém, se seu
              interesse é fazer um curso de proficiência ( Como
              TOEFL,IELTS,CAE,ETC) ou um curso voltado para sua área de estudo,
              é obrigatório ter um nível mínimo do idioma, que varia de curso
              para curso.
            </p>
          </div>

          <iframe
            className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/B5uWP5NqWpg"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </section>

      <section className="text-gray-700 body-font bg-blue-100">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h2 className="title-font sm:text-6xl text-3xl mb-4 font-medium text-gray-900 font-family-louize tracking-tighter">
              Qual o tempo mínimo que eu
              <span className="text-underline"> posso ficar?</span>
            </h2>
            <p className="mb-8 leading-relaxed ">
              Em nosso site nos disponibilizamos a média de tempo para destino
              que você tem interesse, pois cada destino tem suas regras e você
              vai ter que se adequar ao visto para poder permanecer fora.{" "}
              <a
                className="text-blue-400 underline"
                href="https://dreamsintercambios.com.br/destinos"
              >
                Veja Nossos Destinos ✈️
              </a>
            </p>
          </div>

          <iframe
            className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/yIyRNL4ny_I"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </section>

      <section className="text-gray-700 body-font bg-red-100">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h2 className="title-font sm:text-6xl text-3xl mb-4 font-medium text-gray-900 font-family-louize tracking-tighter">
              Qual a melhor <span className="text-underline">Idade?</span>
            </h2>
          </div>
          <div className="flex flex-wrap">
            <div className="lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200">
              <h3 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                Qual Idade mínima para fazer um intercâmbio?
              </h3>
              <p className="leading-relaxed text-base mb-4">
                Para o programa de idioma geral (como inglês, por exemplo) a
                idade mínima é de 16 anos. Já para programas de férias são
                destinados a participantes de 13 a 17 anos.
              </p>
            </div>
            <div className="lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200">
              <h3 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
                Qual Idade máxima para fazer um intercâmbio?
              </h3>
              <p className="leading-relaxed text-base mb-4">
                Intercâmbio não tem limite de idade! Hoje existem programas
                específicos para determinadas idades como o Programa 30+,
                Programa 40+ e Programa 50+.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="text-gray-700 body-font">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h2 className="title-font sm:text-6xl text-3xl mb-4 font-medium text-gray-900 font-family-louize tracking-tighter">
              É necessário ter{" "}
              <span className="text-underline">seguro de viagem</span> para
              fazer intercâmbio?
            </h2>
            <p className="mb-8 leading-relaxed ">
              Sim. Sempre indicamos o intercambistas a viajar com segurança.
              Para isso sempre oferecemos ao nosso cliente um seguro viagem para
              que o estudante fique tranquilo caso tenha algum problema de saúde
              no exterior, lembrando que lá fora tudo é um pouco mais caro do
              que aqui.
            </p>
          </div>

          <Img
            className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 object-cover object-center rounded"
            alt="hero"
            fluid={seguroImg.childImageSharp.fluid}
          />
        </div>
      </section>

      <section className="text-gray-700 body-font bg-blue-100">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h2 className="title-font sm:text-6xl text-3xl mb-4 font-medium text-gray-900 font-family-louize tracking-tighter">
              Quantas <span className="text-underline"> horas de curso</span> as
              escolas de inglês oferecem?
            </h2>
            <p className="mb-8 leading-relaxed ">
              Varia de acordo com cada escola. Existem escolas que oferecem
              cursos intensivos com mais de 5 horas de curso por dia. Existem
              outras que oferecem cursos de menores durações porém atividades
              durante outros horários para complementar o aprendizado.{" "}
              <a
                className="text-blue-400 underline"
                href="https://dreamsintercambios.com.br/destinos"
              >
                Faça seu orçamento aqui ✈️
              </a>
            </p>
          </div>

          <Img
            className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 object-cover object-center rounded"
            alt="hero"
            fluid={classImg.childImageSharp.fluid}
          />
        </div>
      </section>

      <section className="text-gray-700 body-font bg-red-100">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h2 className="title-font sm:text-6xl text-3xl mb-4 font-medium text-gray-900 font-family-louize tracking-tighter">
              <span className="text-underline"> Preciso de visto</span> para
              fazer intercâmbio?
            </h2>
            <p className="mb-8 leading-relaxed ">
              Isso vai depender muito do destino escolhido. Para alguns destinos
              não é necessário ter o visto caso o aluno vá ficar até 6 meses,
              enquanto outros exigem o visto independente de quanto tempo o
              aluno permaneça lá. Por isso é bom sempre consultar a agência para
              saber se o destino que você está planejando ir será necessário a
              aplicação do visto, e consequentemente já adicionar isso ao custo
              de sua viagem.{" "}
              <a
                className="text-blue-400 underline"
                href="https://dreamsintercambios.com.br/destinos"
              >
                Veja Visto de cada Destino ✈️
              </a>
            </p>
          </div>

          <Img
            className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 object-cover object-center rounded"
            alt="hero"
            fluid={visaImg.childImageSharp.fluid}
          />
        </div>
      </section>

      <WhatsappButton />
    </Layout>
  );
};

export default Intercambio;
