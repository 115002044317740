import React from "react";

const WhatsappButton = () => {
  const onClick = () => {
    if (typeof window !== "undefined" && window.fbq) {
      window.fbq("track", "Contact");
    }

    window.location.replace(
      "https://api.whatsapp.com/send?phone=5581982434827&text=Dreams%20me%20ajuda%20a%20realizar%20meu%20sonho"
    );
  };
  return (
    <>
      {/* <div className="fixed rounded-full bg-green-400 text-white w-65   right-0 bottom-0 m-5">
      <button onClick={onClick} className="w-full flex items-center ">
        <div className="w-1/6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 102 102"
          >
            <defs>
              <linearGradient
                id="a"
                x1=".5"
                x2=".5"
                y2="1"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0" stopColor="#61fd7d" />
                <stop offset="1" stopColor="#2bb826" />
              </linearGradient>
            </defs>
            <g transform="translate(.021 -.978)">
              <path
                fill="url(#a)"
                d="M101.971,77.094c0,.558-.017,1.77-.051,2.705a53.717,53.717,0,0,1-.538,6.589,21.949,21.949,0,0,1-1.847,5.521,19.654,19.654,0,0,1-8.653,8.644A21.993,21.993,0,0,1,85.33,102.4a53.913,53.913,0,0,1-6.539.528c-.936.033-2.148.05-2.7.05l-50.223-.008c-.558,0-1.769-.017-2.705-.051a53.744,53.744,0,0,1-6.589-.538,21.951,21.951,0,0,1-5.521-1.847A19.654,19.654,0,0,1,2.4,91.881,21.988,21.988,0,0,1,.557,86.329,53.954,53.954,0,0,1,.029,79.79c-.033-.936-.05-2.148-.05-2.7l.008-50.224c0-.558.017-1.77.051-2.705a53.738,53.738,0,0,1,.538-6.589,21.946,21.946,0,0,1,1.847-5.521A19.655,19.655,0,0,1,11.076,3.4a22,22,0,0,1,5.552-1.848,53.912,53.912,0,0,1,6.539-.528c.936-.033,2.148-.05,2.7-.05L76.095.986c.559,0,1.77.017,2.705.051a53.744,53.744,0,0,1,6.589.538A21.946,21.946,0,0,1,90.91,3.422a19.653,19.653,0,0,1,8.644,8.653,21.988,21.988,0,0,1,1.848,5.552,53.974,53.974,0,0,1,.528,6.539c.033.936.05,2.148.05,2.7l-.008,50.223Z"
                data-name="Path 19"
              />
              <g data-name="Group 2">
                <path
                  fill="#fff"
                  d="M216,146.311a36.58,36.58,0,0,0-57.568,44.119l-5.189,18.948,19.39-5.085a36.561,36.561,0,0,0,17.479,4.451h.015A36.578,36.578,0,0,0,216,146.311ZM190.13,202.568h-.012a30.361,30.361,0,0,1-15.473-4.236l-1.11-.659-11.506,3.017,3.071-11.215-.723-1.15a30.4,30.4,0,1,1,25.754,14.242ZM206.8,179.807c-.914-.457-5.407-2.668-6.245-2.973s-1.447-.457-2.056.457-2.361,2.973-2.894,3.582-1.066.686-1.98.229a24.963,24.963,0,0,1-7.349-4.535,27.531,27.531,0,0,1-5.084-6.329c-.533-.915-.057-1.409.4-1.865.411-.409.914-1.067,1.371-1.6a6.23,6.23,0,0,0,.914-1.524,1.682,1.682,0,0,0-.076-1.6c-.228-.457-2.056-4.954-2.818-6.783-.742-1.782-1.5-1.541-2.056-1.568-.533-.027-1.142-.032-1.752-.032a3.358,3.358,0,0,0-2.437,1.143,10.246,10.246,0,0,0-3.2,7.622c0,4.5,3.275,8.841,3.732,9.451s6.444,9.838,15.612,13.8a52.582,52.582,0,0,0,5.21,1.925,12.535,12.535,0,0,0,5.756.362c1.756-.262,5.407-2.21,6.169-4.344a7.635,7.635,0,0,0,.533-4.344c-.229-.381-.838-.61-1.752-1.067Z"
                  data-name="Path 20"
                  transform="translate(-138.001 -121.202)"
                />
              </g>
            </g>
          </svg>
        </div>
        <div className="w-5/6 p-2 text-sm"> Chama a gente no Whats</div>
      </button>
    </div> */}
    </>
  );
};

export default WhatsappButton;
